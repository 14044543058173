import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Box, Text } from 'rebass'
import Hero from '../components/base/Hero'
import Link from '../components/base/Link'
import BackedByIag from '../components/base/BackedByIag'
import { modelSlugToRoute } from '../utils/links'

const Blog = () => (
  <StaticQuery query={graphql`
    query BlogIndexQuery {
      allDatoCmsBlog {
        edges {
          node {
            id
            slug
            title
            meta {
              publishedAt(fromNow: true)
            }
          }
        }
      }
    }
  `}
  render={data => (
    <>
      <Hero sx={{ py: 0 }}>
        <Heading as="h1" variant="hero.heading">Blog</Heading>
      </Hero>
      <Box my={5} variant="fixed">
        {data.allDatoCmsBlog.edges.map(({ node: blog }) =>
          <Box key={blog.id} my={2}>
            <Heading
              as="h3"
              variant='heading'
              fontWeight={500}
              lineHeight={1.6}
            >
              <Link to={modelSlugToRoute('blog', blog.slug)}>
                {blog.title}
              </Link>
            </Heading>
            <Text>Published {blog.meta.publishedAt}.</Text>
          </Box>
        )}
      </Box>
      <BackedByIag mt={5} />
    </>
  )}
  />
)

export default Blog
